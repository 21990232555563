import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { SubStepActionEnum } from "../../pages/StepChoose/enums";
import { OrderType } from "../../pages/StepChoose/Ethernet/types";
import { ProductTypesEnum } from "../../shared/enums";
import { getDCEthernetAddressValid } from "../../shared/utils/addresses";
import {
  isValidEmail,
  isValidMobileNumber,
} from "../../shared/utils/validation";
import { processCreditVetAndFetchStatus } from "../account/actions";
import {
  getAccountSettings,
  getValidForResign,
  getIsSalesPerson,
  shouldProcessCreditVet,
} from "../account/selectors";
import { saveDraftState } from "../drafts/actions";
import { setEthernetConfigPurchaseArgsSubmitted } from "../ethernetProducts/actions";
import { EthernetTypeEnum } from "../ethernetProducts/constants";
import {
  validateEthernetConfigurations,
  ethernetPurchaseArgsValidationErrors,
} from "../ethernetProducts/selectors";
import {
  getHardwareCreditError,
  allHardwareDeliveryPopulated,
} from "../hardware/configurations/selectors";
import {
  getHasDaisyFreshHardware,
  getHardwareCount,
} from "../hardware/selectors";
import { addressTypes } from "../hardwareDelivery/constants";
import { validateAllConfigProperties } from "../mobile/actions";
import { getDaisyFreshAvailableForContractLength } from "../mobile/selectors/daisyFresh";
import { getMobileSIMsRequired } from "../mobile/selectors/delivery";
import {
  validateMobileConfigs,
  getBillLimitsNotSet,
  getSpendCapsNotSet,
} from "../mobile/selectors/productConfig";
import {
  hasResignsWithoutChange,
  getResignWithoutChangeProduct,
  hasResignsWithoutProductId,
} from "../mobile/selectors/resigns";
import { addAllProductsToOrder } from "../order/actions";
import {
  getDoesOrderNotHaveAnyProducts,
  getDoesOrderHaveMissingProducts,
} from "../order/selectors";
import {
  getIsUniversalProductConfigValid,
  getDoesUniversalProductHaveDocuments,
} from "../universalProducts/selectors";
import { addAppointments } from "../wlrBroadband/actions/configurations";
import {
  BROADBAND_ONLY,
  EXISTING_SOGEA,
  NEW_SOGEA,
} from "../wlrBroadband/constants";
import {
  getDaisyOwnedLineSelected,
  getUnspecifiedSingleConnectivityConfigs,
  validateWlrBroadbandConfigs,
} from "../wlrBroadband/selectors";
import {
  STEP_SUMMARY,
  STEP_CHOOSE,
  STEP_CUSTOMISE,
  STEP_ACCOUNT,
  STEP_DELIVERY,
} from "./constants";

export const resetUiState = createAction("uiState/resetUiState");
export const addAlertMessage = createAction<any>("uiState/addAlertMessage");
export const removeAlertMessages = createAction("uiState/removeAlertMessages");
export const addFlashMessage = createAction<string>("uiState/addFlashMessage");
export const removeFlashMessageById = createAction<string | number>(
  "uiState/removeFlashMessageById"
);
export const toggleStepOneMobile = createAction("uiState/toggleStepOneMobile");
export const toggleStepOneHardware = createAction(
  "uiState/toggleStepOneHardware"
);
export const toggleStepOneEthernet = createAction(
  "uiState/toggleStepOneEthernet"
);
export const toggleStepOneWlrBroadband = createAction(
  "uiState/toggleStepOneWlrBroadband"
);
export const toggleStepOneUniversalProducts = createAction(
  "uiState/toggleStepOneUniversalProducts"
);
export const toggleStepOneMonitoringServiceProducts = createAction(
  "uiState/toggleStepOneMonitoringServiceProducts"
);
export const toggleOrderStatus = createAction("uiState/toggleOrderStatus");
export const showFinalStepWarning = createAction(
  "uiState/showFinalStepWarning"
);
export const hideFinalStepWarning = createAction(
  "uiState/hideFinalStepWarning"
);
export const confirmFinalStepWarning = createAction(
  "uiState/confirmFinalStepWarning"
);
export const setHardwareConfig = createAction<any>("uiState/setHardwareConfig");
export const setShowMobileResigns = createAction(
  "uiState/setShowMobileResigns"
);
export const setShowWlrResigns = createAction("uiState/setShowWlrResigns");
export const setProductTypes = createAction<any>("uiState/setProductTypes");
export const setStep = createAction<string>("uiState/setStep");
export const setSubStepChoose = createAction<string>(
  "uiState/setSubStepChoose"
);

export const setActiveStep = createAsyncThunk(
  "uiState/setActiveStep",
  async (step: string, { dispatch, getState, rejectWithValue }) => {
    const state: any = getState();
    const getIsAccountCreatedState = () =>
      _.get(state.account.createAccount.response, "status") === "success";
    const {
      mobile,
      wlrBroadband,
      ethernetProducts,
      hardwareDelivery,
      order,
      uiState: { visited, steps, finalStepWarningShown, activeStep },
    } = state;
    const accountSettings = getAccountSettings(state);

    const setStepAndView = () => {
      const element = document.getElementsByClassName("WizardClass")[0];
      if (element !== undefined) element.scrollIntoView(true);
      dispatch(setStep(step));
    };

    const reject = (error?: string, showMessage = true) => {
      if (showMessage) dispatch(addAlertMessage(error));
      return rejectWithValue(error);
    };

    const stepIndex = steps.indexOf(step);
    if (stepIndex > 1 && !visited[stepIndex - 1]) {
      if (activeStep === STEP_CHOOSE) {
        if (getDoesOrderNotHaveAnyProducts(state)) {
          return reject("You must select at least one product to proceed.");
        }
      } else if (activeStep === STEP_CUSTOMISE) {
        if (!validateMobileConfigs(getState())) {
          return reject(
            "Please correct errors in your mobile product configurations in order to continue."
          );
        }
      } else return reject("A step can't be missed out.", false);
    }
    if (activeStep === STEP_SUMMARY)
      return reject("Can't go back from summary", false);

    if (step === STEP_CHOOSE) {
      setStepAndView();
    }

    if (step === STEP_CUSTOMISE) {
      if (getDoesOrderHaveMissingProducts(state)) {
        return reject(
          "You must select at least one product for each selected type to proceed."
        );
      }

      const unusedLocations = wlrBroadband.locations.filter(
        (location: any, index: number) => {
          const locationHasConfig = wlrBroadband.configurations.find(
            (config: any) => config.locationIndex === index
          );
          return !locationHasConfig;
        }
      );
      if (wlrBroadband.locations.length > 1 && unusedLocations.length) {
        return reject(
          "You have added a location and not selected a product. Please select a product or remove the location to continue."
        );
      }

      if (
        accountSettings.as_stop_daisy_line_orders === "1" &&
        getDaisyOwnedLineSelected(state)
      ) {
        return reject(
          "Sorry, you have selected an existing Daisy line. You may not proceed with this order."
        );
      }

      if (
        hasResignsWithoutChange(state) &&
        !getResignWithoutChangeProduct(state)
      ) {
        return reject(
          "No resign product returned from Daisy Central 1. Please contact support."
        );
      }

      if (
        ethernetProducts.configurations.find(
          (c: any) =>
            !getDCEthernetAddressValid(c.purchaseArgs, "site_b_") ||
            (c.type === EthernetTypeEnum.ETHERNET_PRODUCT_PTP &&
              !getDCEthernetAddressValid(c.purchaseArgs, "site_a_"))
        )
      ) {
        return reject(
          "You must choose valid installation addresses for your ethernet products to proceed."
        );
      }

      if (
        ethernetProducts.configurations.find(
          (c: any) =>
            !_.isEmpty(c.site_a_full_address) &&
            !c.site_a_full_address.thoroughfareNumber &&
            !c.site_a_full_address.premisesName
        )
      ) {
        return reject("You must provide address Number or Building name.");
      }

      if (
        ethernetProducts.configurations.find(
          (c: any) =>
            !_.isEmpty(c.site_b_full_address) &&
            !c.site_b_full_address.thoroughfareNumber &&
            !c.site_b_full_address.premisesName
        )
      ) {
        return reject("You must provide address Number or Building name.");
      }

      if (
        wlrBroadband.configurations.find(
          (c: any) =>
            wlrBroadband.locations[c.locationIndex]?.type === BROADBAND_ONLY &&
            !c.broadbandProductId
        )
      ) {
        return reject("You must select a broadband product to continue.");
      }

      setStepAndView();
    }

    if (step === STEP_ACCOUNT) {
      if (getIsAccountCreatedState()) {
        return reject("Account already created", false);
      }
    }

    if (step === STEP_DELIVERY || step === STEP_ACCOUNT) {
      if (getDoesOrderNotHaveAnyProducts(state)) {
        return reject("You must select at least one product to proceed.");
      }
      dispatch(
        validateAllConfigProperties(
          accountSettings.can_access_vf_direct === "1"
        )
      );
      if (!validateMobileConfigs(getState())) {
        return reject(
          "Please correct errors in your mobile product configurations in order to continue."
        );
      }

      const unusedLocations = wlrBroadband.locations.filter(
        (location: any, index: number) => {
          const locationHasConfig = wlrBroadband.configurations.find(
            (config: any) => config.locationIndex === index
          );
          return !locationHasConfig;
        }
      );
      if (wlrBroadband.locations.length > 1 && unusedLocations.length) {
        return reject(
          "You have added a location and not selected a product. Please select a product or remove the location to continue."
        );
      }

      const hardwareCreditError = getHardwareCreditError(getState());
      if (hardwareCreditError) return reject(hardwareCreditError as string);

      if (!validateWlrBroadbandConfigs(getState())) {
        return reject(
          "There are errors in the data you have entered for Lines, Calls & Broadband configurations."
        );
      }

      if (!validateEthernetConfigurations(getState())) {
        return reject(
          "Please select a quote for each ethernet order to continue."
        );
      }

      if (
        mobile.configs.length &&
        !mobile.billCapConfirmed &&
        !(
          (getBillLimitsNotSet(state) as any) ^
          (getSpendCapsNotSet(state) as any)
        ) &&
        accountSettings.dws_reseller_enabled !== "1" &&
        activeStep === STEP_CUSTOMISE
      ) {
        return reject(
          accountSettings.can_access_vf_direct
            ? "Please confirm you have offered a spend cap before proceeding."
            : "Please confirm you have offered a mobile bill cap before proceeding."
        );
      }

      if (
        !getDaisyFreshAvailableForContractLength(state) &&
        getHasDaisyFreshHardware(state)
      ) {
        return reject(
          "You must select a 24 - 36 month contract length when your order contains daisy fresh."
        );
      }

      if (
        !state.universalProducts.configs.every(getIsUniversalProductConfigValid)
      ) {
        return reject(
          "The order contains one or more unpriced products please remove to continue."
        );
      }

      if (
        !state.universalProducts.configs.every(
          getDoesUniversalProductHaveDocuments
        ) &&
        state.order.hostEnv !== "dc"
      ) {
        return reject(
          "Warning: You have not attached any document to this order meaning no order breakdown/summary will be attached to this contract. Please ensure this is uploaded before continuing."
        );
      }

      const { emailAddress, phoneNumber } =
        state.monitoringService.alertPreferences;

      if (
        (emailAddress && !isValidEmail(emailAddress)) ||
        (phoneNumber && !isValidMobileNumber(phoneNumber))
      ) {
        return reject("Email/Mobile fields are not validated", false);
      }

      if (step === STEP_DELIVERY && steps.includes(STEP_ACCOUNT)) {
        if (!getIsAccountCreatedState()) {
          if (activeStep === STEP_ACCOUNT)
            return reject("Please select Create Account in order to continue.");
          else return reject("Please fill in account details first.");
        }
      }

      let errors = [];
      ethernetProducts.configurations.forEach((config: any, index: number) => {
        if (config.type === EthernetTypeEnum.ETHERNET_PRODUCT_PTP) {
          errors = ethernetPurchaseArgsValidationErrors(state, index);
          dispatch(setEthernetConfigPurchaseArgsSubmitted(index));
        } else {
          errors = ethernetPurchaseArgsValidationErrors(state, index).filter(
            (field) => !field.startsWith("site_a")
          );
          dispatch(setEthernetConfigPurchaseArgsSubmitted(index));
        }
      });
      if (errors.length > 0) {
        return reject(
          "Please correctly complete each Ethernet configuration before continuing."
        );
      }

      setStepAndView();
    }

    if (step === STEP_SUMMARY) {
      if (activeStep === STEP_DELIVERY) {
        if (
          order.orderContactId === "" &&
          accountSettings.dws_reseller_enabled !== "1"
        ) {
          return reject("Please select a contact for this order.");
        }

        if (
          order.customer_purchase_number &&
          order.customer_purchase_number.length > 15
        ) {
          return reject(
            "Purchase order number can be no more than 15 characters (incl. spaces)"
          );
        }

        if (
          typeof order.ofcomPricingInfo === "string" &&
          order.ofcomPricingInfo.length < 1
        ) {
          return reject(
            "Ofcom pricing information is required for this order."
          );
        }

        if (getHardwareCount(state) || getMobileSIMsRequired(state)) {
          if (
            !isValidEmail(order.trackingDetails) &&
            !isValidMobileNumber(order.trackingDetails)
          ) {
            return reject(
              "Tracking details must be a valid email address or mobile number."
            );
          }
          if (!allHardwareDeliveryPopulated(state)) {
            return reject("Please select delivery options for all hardware.");
          }
        }

        if (
          hardwareDelivery.addressType === addressTypes.BILLING &&
          !getValidForResign(state)
        ) {
          return reject(
            "Please update low quality billing address before proceeding with this order."
          );
        }

        if (!finalStepWarningShown) {
          dispatch(showFinalStepWarning());
          return reject("No final step warning shown", false);
        }

        setStepAndView();
      }

      if (getIsSalesPerson(state) && wlrBroadband.configurations.length > 0) {
        await dispatch(addAppointments());
      }
      // @ts-ignore
      await dispatch(addAllProductsToOrder(false));
    }

    if (step !== STEP_CHOOSE && order.id) {
      await dispatch(saveDraftState({}));
    }
  }
);

export const setActiveSubStepChoose = createAsyncThunk(
  "uiState/setActiveSubStepChoose",
  async (direction: string, { dispatch, getState, rejectWithValue }) => {
    const state: any = getState();
    const {
      mobile,
      universalProducts,
      monitoringService,
      wlrBroadband,
      ethernetProducts,
      uiState: { productTypes, activeSubStepChoose },
    } = state;

    const accountSettings = getAccountSettings(state);
    const subSteps = [ProductTypesEnum.SELECT, ...productTypes];
    const activeSubStep = activeSubStepChoose ?? ProductTypesEnum.SELECT;
    const activeSubStepIndex = subSteps.indexOf(activeSubStep);

    const canAccessNewCoterminus =
      accountSettings.as_can_access_new_coterminus === "1";
    const canAccessVfDirect = accountSettings.can_access_vf_direct === "1";

    const reject = (error?: string, showMessage = true) => {
      if (showMessage) dispatch(addAlertMessage(error));
      return rejectWithValue(error);
    };

    if (direction === SubStepActionEnum.BACK) {
      dispatch(setSubStepChoose(subSteps[activeSubStepIndex - 1]));
      return;
    }

    if (direction === SubStepActionEnum.CONTINUE) {
      const isLastSubStep = activeSubStepIndex === subSteps.length - 1;

      const setSubStepAndView = () => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        dispatch(setSubStepChoose(subSteps[activeSubStepIndex + 1]));
      };
      const setStepAndView = () => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        if (
          accountSettings.can_access_vf_direct === "1" &&
          !(getState() as any).uiState.steps.includes(STEP_ACCOUNT) &&
          shouldProcessCreditVet(state)
        ) {
          dispatch(processCreditVetAndFetchStatus());
        }
        dispatch(setStep(STEP_CUSTOMISE));
      };

      if (
        wlrBroadband.locations.find(
          (c: any) =>
            (c.type === NEW_SOGEA || c.type === EXISTING_SOGEA) &&
            c.sogeaTermsAccepted === false
        ) &&
        accountSettings.dws_reseller_enabled === "1"
      ) {
        return reject("You must agree to the terms and conditions to proceed.");
      }
      const alertMessage = "You must select at least one product to proceed.";
      if (activeSubStep === ProductTypesEnum.MOBILE) {
        if (hasResignsWithoutProductId(state) && canAccessVfDirect) {
          return reject(
            "You must choose a product for every number selected to Resign."
          );
        }
        if (mobile.configs.length < 1) {
          return reject(alertMessage);
        }
        if (
          hasResignsWithoutChange(state) &&
          !getResignWithoutChangeProduct(state)
        ) {
          return reject(
            "No resign product returned from Daisy Central 2. Please contact support."
          );
        }
        if (mobile.contractCoTerminus) {
          if (!canAccessVfDirect && canAccessNewCoterminus) {
            if (
              !mobile.coTerminusContractEndDate ||
              mobile.contractLengthInMonthsAllProducts !== 0
            ) {
              return reject(
                "You must select an end date for co-term or toggle off co-term to proceed on the standard contract length."
              );
            }
          } else {
            if (
              !mobile.contractLengthInMonthsAllProducts ||
              mobile.contractLengthInMonthsAllProducts === "0" ||
              mobile.contractLengthInMonthsAllProducts === 0
            ) {
              return reject(
                "You must select number of months for co-term or toggle off co-term to proceed on the standard contract length."
              );
            }
          }
        }
      }

      if (activeSubStep === ProductTypesEnum.ETHERNET) {
        if (ethernetProducts.configurations.length < 1) {
          return reject(alertMessage);
        }
        if (
          ethernetProducts.configurations.find(
            (c: any) => c.orderType === OrderType.RENEWAL
          )
        ) {
          return reject(
            "This order should be processed manually in Daisy Central."
          );
        }
        if (
          ethernetProducts.configurations.find(
            (c: any) =>
              !getDCEthernetAddressValid(c.purchaseArgs, "site_b_") ||
              (c.type === EthernetTypeEnum.ETHERNET_PRODUCT_PTP &&
                !getDCEthernetAddressValid(c.purchaseArgs, "site_a_"))
          )
        ) {
          return reject(
            "You must choose valid installation addresses for your ethernet products to proceed."
          );
        }
        if (
          ethernetProducts.configurations.find(
            (c: any) =>
              !_.isEmpty(c.site_a_full_address) &&
              !c.site_a_full_address.thoroughfareNumber &&
              !c.site_a_full_address.premisesName
          )
        ) {
          return reject("You must provide address Number or Building name.");
        }
        if (
          ethernetProducts.configurations.find(
            (c: any) =>
              !_.isEmpty(c.site_b_full_address) &&
              !c.site_b_full_address.thoroughfareNumber &&
              !c.site_b_full_address.premisesName
          )
        ) {
          return reject("You must provide address Number or Building name.");
        }
        if (
          ethernetProducts.configurations.find(
            (c: any) =>
              _.isEmpty(c.selectedRouterId) &&
              (c.type === EthernetTypeEnum.ETHERNET_PRODUCT_EFM ||
                c.type === EthernetTypeEnum.ETHERNET_PRODUCT_MANAGED ||
                c.type === EthernetTypeEnum.ETHERNET_PRODUCT_GEA)
          )
        ) {
          return reject("You must choose a router.");
        }
      }

      if (activeSubStep === ProductTypesEnum.BROADBAND) {
        if (
          wlrBroadband.configurations.find(
            (c: any) =>
              wlrBroadband.locations[c.locationIndex]?.type ===
                BROADBAND_ONLY && !c.broadbandProductId
          )
        ) {
          return reject("You must select a broadband product to continue.");
        }
        if (getUnspecifiedSingleConnectivityConfigs(state).length) {
          return reject("You must choose a connectivity product.");
        }
        if (wlrBroadband.configurations.length < 1) {
          return reject(alertMessage);
        }
        const unusedLocations = wlrBroadband.locations.filter(
          (location: any, index: number) => {
            const locationHasConfig = wlrBroadband.configurations.find(
              (config: any) => config.locationIndex === index
            );
            return !locationHasConfig;
          }
        );
        if (wlrBroadband.locations.length > 1 && unusedLocations.length) {
          return reject(
            "You have added a location and not selected a product. Please select a product or remove the location to continue."
          );
        }
        if (
          accountSettings.as_stop_daisy_line_orders === "1" &&
          getDaisyOwnedLineSelected(state)
        ) {
          return reject(
            "Sorry, you have selected an existing Daisy line. You may not proceed with this order."
          );
        }
      }

      if (activeSubStep === ProductTypesEnum.HARDWARE) {
        if (getHardwareCount(state) < 1) return reject(alertMessage);
      }

      if (activeSubStep === ProductTypesEnum.UNIVERSAL) {
        if (universalProducts.configs.length < 1) return reject(alertMessage);
      }

      if (activeSubStep === ProductTypesEnum.MONITORING) {
        if (monitoringService.length < 1) return reject(alertMessage);
      }

      if (isLastSubStep) {
        setStepAndView();
      } else {
        setSubStepAndView();
      }
    }
  }
);
