import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { get, union } from "lodash";
import initialState from "./state";
import { initCpq } from "../_root/actions";
import {
  approveOrRejectApproval,
  getMoreOrdersForAccount,
  getNewOrder,
  getOrdersForAccount,
  getPricingRequest,
  listDocuments,
  provisionOrder,
  rebookExpiredReservations,
  recalculatePrices,
  removeBoltOn,
  resetOrderState,
  resetUploadDocuments,
  resetUploadStatus,
  sendForApproval,
  setAccountId,
  setContractLength,
  setContractMeta,
  setCustomerReference,
  setDocumentsMeta,
  setField,
  setOrderContact,
  setRecurringPrice,
  setTotalOneOffPrice,
  updateOrderStatus,
  updateOrderTracking,
  uploadContract,
  uploadDocuments,
} from "./actions";
import { fetchAccountSettings } from "../account/actions";
import { todaysDateString } from "../../shared/utils/date";
import { setTrackingDetails } from "./trackingDetailsActions";

const order = createSlice({
  name: "order",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(initCpq, (state, action: PayloadAction<any>) => {
        const payload = action.payload;
        state.accountId = payload.accountId;
        state.accountName = payload.accountName;
        state.platformCustomerReference = payload.platformCustomerReference;
        state.id = payload.orderId;
        state.leadId = payload.leadId;
        state.leadNumber = payload.leadNumber;
        state.opportunityId = payload.opportunityId;
        state.opportunityNumber = payload.opportunityNumber;
        state.opportunityName = payload.opportunityName;
        state.customerName = payload.customerName;
        state.hostEnv = payload.hostEnv;
        state.userResignsPermission = payload.userResignsPermission;
        state.baseUrl = payload.baseUrl ?? "/";
        state.leadName = payload.leadName;
        state.unrestrict = payload.unrestrict;
      })
      .addCase(getNewOrder.pending, (state) => {
        state.orderStatus.fetching = true;
      })
      .addCase(getNewOrder.fulfilled, (state, action: PayloadAction<any>) => {
        const payload = action.payload;
        state.orderStatus = {
          fetching: false,
          response: payload,
        };
        state.id = get(payload, "data.id");
        state.platformCustomerReference =
          payload.data.platform_customer_reference;
        state.opportunityId = get(payload, "data.opportunity.id");
        state.opportunityNumber = get(payload, "data.opportunity.number");
      })
      .addCase(
        fetchAccountSettings.fulfilled,
        (state, action: PayloadAction<any>) => {
          // Default suppression of welcome emails for VF Direct users.
          // Would be better to do this via DC, but never mind 🤨
          const { settings } = action.payload;
          state.suppress_welcome_email = settings?.can_access_vf_direct === "1";
          if (settings?.as_ofcom_text_required === "1") {
            state.ofcomPricingInfo = "";
          }
        }
      )
      .addCase(updateOrderStatus.pending, (state) => {
        state.orderStatus.fetching = true;
      })
      .addCase(
        updateOrderStatus.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.orderStatus = {
            fetching: false,
            response: action.payload,
          };
        }
      )
      .addCase(setOrderContact, (state, action: PayloadAction<any>) => {
        state.orderContactId = action.payload;
      })
      .addCase(sendForApproval.pending, (state) => {
        state.sendForApproval.fetching = true;
      })
      .addCase(
        sendForApproval.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.sendForApproval = {
            fetching: false,
            response: action.payload,
          };
        }
      )
      .addCase(approveOrRejectApproval.pending, (state) => {
        state.approveApproval.fetching = true;
      })
      .addCase(
        approveOrRejectApproval.fulfilled,
        (state, action: PayloadAction<any>) => {
          // We don't want to wipe existing order state from the store while approval is processing so....
          if (action.payload.status !== "pending") {
            state.approveApproval.fetching = false;
            state.orderStatus.response = action.payload;
          }
        }
      )
      .addCase(setContractLength, (state, action: PayloadAction<any>) => {
        state.contractLength = action.payload;
      })
      .addCase(updateOrderTracking.pending, (state) => {
        state.orderStatus.fetching = true;
      })
      .addCase(
        updateOrderTracking.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.orderStatus = {
            fetching: false,
            response: action.payload,
          };
        }
      )
      .addCase(recalculatePrices.pending, (state) => {
        state.recalculatePrices.fetching = true;
      })
      .addCase(
        recalculatePrices.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.recalculatePrices = {
            fetching: false,
            response: action.payload,
          };
        }
      )
      .addCase(setContractMeta, (state, action: PayloadAction<any>) => {
        const payload = action.payload;
        const contractUpload = state.contractUpload;
        state.contractUpload = {
          sending: contractUpload.sending,
          response: contractUpload.response,
          receivedAt: contractUpload.receivedAt,
          signedAt: contractUpload.signedAt,
          file: contractUpload.file,
          [payload.key]: payload.value,
        };
      })
      .addCase(uploadContract.pending, (state) => {
        state.contractUpload.sending = true;
      })
      .addCase(
        uploadContract.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.contractUpload.sending = false;
          state.contractUpload.response = action.payload;
        }
      )
      .addCase(resetUploadStatus, (state) => {
        state.contractUpload = {
          sending: false,
          response: false,
          receivedAt: todaysDateString,
          signedAt: todaysDateString,
          file: false,
        };
      })

      // Tracking Details

      .addCase(setTrackingDetails, (state, action: PayloadAction<any>) => {
        state.trackingDetails = action.payload;
      })

      .addCase(removeBoltOn.pending, (state, action) => {
        const { removal } = action.meta.arg;
        state.boltOnRemovals = {
          [removal.id]: {
            fetching: true,
            response: {},
          },
        };
      })
      .addCase(removeBoltOn.fulfilled, (state, action) => {
        const { removal } = action.meta.arg;
        state.boltOnRemovals = {
          [removal.id]: {
            fetching: false,
            response: action.payload.response,
          },
        };
      })
      .addCase(setField, (state, action: PayloadAction<any>) => {
        const payload = action.payload;
        // @ts-ignore
        state[payload.name] = payload.value;
      })
      .addCase(getOrdersForAccount.pending, (state) => {
        state.ordersForAccount.fetching = true;
      })
      .addCase(
        getOrdersForAccount.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.ordersForAccount = {
            fetching: false,
            response: action.payload,
          };
        }
      )
      .addCase(
        getMoreOrdersForAccount.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          const payload = action.payload;
          if (payload.status === "success") {
            const orders = state.ordersForAccount?.response?.orders || [];
            state.ordersForAccount = {
              fetching: false,
              response: {
                orders: union(orders, payload.orders),
              },
            };
          } else {
            state.ordersForAccount = {
              fetching: false,
              response: action.payload,
            };
          }
        }
      )
      .addCase(provisionOrder.pending, (state) => {
        state.provision.fetching = true;
        state.provision.response = {};
      })
      .addCase(
        provisionOrder.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.provision = {
            fetching: false,
            response: action.payload,
          };
        }
      )
      .addCase(setAccountId, (state, action: PayloadAction<any>) => {
        state.accountId = action.payload;
      })
      .addCase(setCustomerReference, (state, action: PayloadAction<any>) => {
        state.platformCustomerReference = action.payload;
      })
      .addCase(resetOrderState, (state) => {
        return {
          ...initialState,
          id: false,
          accountId: false,
        };
      })
      .addCase(getPricingRequest.pending, (state) => {
        state.pricingRequest.fetching = true;
      })
      .addCase(
        getPricingRequest.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.pricingRequest = {
            fetching: false,
            response: action.payload,
          };
        }
      )
      .addCase(setTotalOneOffPrice, (state, action: PayloadAction<any>) => {
        state.totalOneOffPrice = action.payload;
      })
      .addCase(setRecurringPrice, (state, action: PayloadAction<any>) => {
        state.totalRecurringPrice = action.payload;
      })
      .addCase(rebookExpiredReservations.pending, (state) => {
        state.rebookExpiredReservations.fetching = true;
      })
      .addCase(
        rebookExpiredReservations.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.rebookExpiredReservations = {
            fetching: false,
            response: action.payload,
          };
        }
      )
      .addCase(uploadDocuments.pending, (state) => {
        state.documentsUpload.sending = true;
      })
      .addCase(
        uploadDocuments.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.documentsUpload = {
            sending: false,
            response: action.payload,
            files: state.documentsUpload.files,
          };
        }
      )
      .addCase(resetUploadDocuments, (state) => {
        state.documentsUpload = {
          sending: false,
          response: false,
          files: false,
        };
      })
      .addCase(setDocumentsMeta, (state, action: PayloadAction<any>) => {
        const payload = action.payload;
        state.documentsUpload = {
          sending: state.documentsUpload.sending,
          response: state.documentsUpload.response,
          files: state.documentsUpload.files,
          [payload.key]: payload.value,
        };
      })
      .addCase(listDocuments.pending, (state) => {
        state.listDocuments.fetching = true;
      })
      .addCase(listDocuments.fulfilled, (state, action: PayloadAction<any>) => {
        state.listDocuments = {
          fetching: false,
          response: action.payload,
        };
      });
  },
});

export default order.reducer;
