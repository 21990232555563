import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { getHostEnv } from "./selectors/getHostEnv";
import format from "date-fns/format";
import * as OrdersAPI from "../../api/v1/orders";
import * as OrderProductAPI from "../../api/v1/orderProduct";
import * as AffinityAPI from "../../api/v1/affinity";
import * as GuidedSalesAPI from "../../api/v1/guidedSales";
import { getIndividualTotalPrice } from "../../shared/utils/getIndividualTotalPrice";
import { getOneOffPriceWithCredits } from "../../shared/utils/getOneOffPriceWithCredits";
import { get } from "lodash";
import { saveDraftState } from "../drafts/actions";
import {
  isValidEmail,
  isValidMobileNumber,
} from "../../shared/utils/validation";
import { requestAllEthernetPurchases } from "../ethernetProducts/actions";
import {
  orderBoltOns,
  orderHardwareCredits,
  orderMobileTariffs,
  orderShipping,
} from "../mobile/selectors/order";
import { orderWlrBroadbandProducts } from "../wlrBroadband/actions/configurations";
import { orderHardware } from "../hardware/hardwareOrders/actions";
import { orderUniversalProducts } from "../universalProducts/actions";
import { orderLogicMonitorProducts } from "../monitoringService/actions";
import { getAccountSettings } from "../account/selectors";
import { getALBRemovals } from "../mobile/selectors/boltOns";
import { getAccountId, getOrderId } from "./selectors";
import { setGenerateQuoteFetching } from "../quote/actions";

export const getNewOrder = createAsyncThunk(
  "order/getNewOrder",
  async (orderName: string | undefined, { getState }: any) => {
    if (!orderName) {
      const hostEnv = getHostEnv(getState());
      orderName = `${hostEnv}_${format(new Date(), "yyyyMMddHHmm")}`;
    }
    const account = getState().order.accountId;
    return await OrdersAPI.create(account, orderName);
  }
);

export const getOrderStatus = createAsyncThunk(
  "order/getOrderStatus",
  async (_, { dispatch }: any) => {
    const actionResp = await dispatch(updateOrderStatus());
    const response = actionResp.payload;

    if (response.status === "success") {
      const price = response?.data?.price;
      // For recurring total price, calculate individually for now so we can get the prices without promotion.
      // To revisit in future for TP67875.
      dispatch(
        setRecurringPrice(
          getIndividualTotalPrice(response?.data?.products || [])
        )
      );
      dispatch(
        setTotalOneOffPrice(
          getOneOffPriceWithCredits(
            response?.data?.products,
            price?.one_off_price_with_promotions
          )
        )
      );
    }

    return response;
  }
);

export const updateOrderStatus = createAsyncThunk(
  "order/updateOrderStatus",
  async (_, { getState }: any) => {
    const { id, accountId, leadId } = getState().order;
    return await OrdersAPI.view(
      id,
      accountId === null ? "" : accountId,
      leadId
    );
  }
);

export const setContractMeta = createAction(
  "order/setContractMeta",
  (key: any, value: any) => ({
    payload: { key, value },
  })
);

/**
 * Set the selected order contact.
 * ID is a UUID from the list returned from AllAccountContacts
 * @param id
 * @returns {{id: *, type: string}}
 */
export const setOrderContact = createAction<string>("order/setOrderContact");

/**
 * Set contract length
 * Currently just used for universal products.
 * TODO: Use globally. Currently separate ones exit for WLR / Mobile.
 * ...which is silly. There's only a single contract length per order.
 *
 * @param contractLength
 * @returns {{type: string, contractLength: *}}
 */
export const setContractLength = createAction<string | number>(
  "order/setContractLength"
);

/**
 * Update early termination fee method on order.
 * This is applicable to resigns that happen when the user still has time left on an existing contract
 *
 * @param event
 * @returns {Function}
 */
export const updateETFMethod = createAsyncThunk(
  "order/updateETFMethod",
  async (event: any, { getState }: any) => {
    const order = getState().order;
    if (order.orderStatus.fetching) return false;
    return await OrdersAPI.update(order.id, {
      account: order.accountId,
      etf_method: event.target.value,
    });
  }
);

export const uploadContract = createAsyncThunk(
  "order/uploadContract",
  async (_, { getState, dispatch }: any) => {
    const actionResp = await dispatch(uploadSignedContractPost());
    const response = actionResp.payload;

    if (response.status === "error") return;

    // Also request approval after upload has completed if necessary
    const approval = get(
      getState().order.orderStatus.response,
      "data.approval",
      {}
    );

    if (approval) {
      dispatch(sendForApproval());
    }

    return response;
  }
);

const uploadSignedContractPost = createAsyncThunk(
  "order/uploadSignedContractPost",
  async (_, { getState }: any) => {
    const state = getState();
    return await OrdersAPI.uploadSignedContractPost(
      state.order.accountId,
      state.order.id,
      state.order.contractUpload.receivedAt,
      state.order.contractUpload.signedAt,
      state.order.orderContactId,
      state.order.contractUpload.file
    );
  }
);

export const resetUploadStatus = createAction("order/resetUploadStatus");

export const sendForApproval = createAsyncThunk(
  "order/sendForApproval",
  async (_, { dispatch }: any) => {
    const actionResp = await dispatch(submitApproval());
    const response = actionResp.payload;
    dispatch(saveDraftState({}));
    return response;
  }
);

const submitApproval = createAsyncThunk(
  "order/submitApproval",
  async (_, { getState }: any) => {
    const order = getState().order;
    // Don't resubmit approval. This would happen in the LE flow where requires_external_approval_for_external_user_orders
    // is set and approval is requested BEFORE a contract is uploaded. ....which is to do with sales people needing
    // customers to sign while they're on the phone, not waiting for the lead time in getting the actual approval.
    // The contracts have a get-out clause apparently. TP9707
    if (get(order, "sendForApproval.response.data.submitted") === 1) return;
    const response = await OrdersAPI.sendForApproval(order.id, order.accountId);
    return response;
  }
);

/**
 * Approve an approval. Used for Love Energy.
 * @param reject {boolean}
 * @returns {function(...[*]=)}
 */
export const approveOrRejectApproval = createAsyncThunk(
  "order/approveOrRejectApproval",
  async (reject: boolean, { dispatch }: any) => {
    const actionResp = await dispatch(approveOrReject(reject));
    const response = actionResp.payload;
    if (!reject && response.status === "success") {
      await dispatch(getOrderStatus());
    }
    dispatch(saveDraftState({}));
    return response;
  }
);

export const approveOrReject = createAsyncThunk(
  "order/approveOrReject",
  async (reject: boolean, { getState }: any) => {
    const order = getState().order;
    return reject
      ? await AffinityAPI.ApprovalsReject(order.id)
      : await AffinityAPI.ApprovalsApprove(order.id);
  }
);

export const updateOrderTracking = createAsyncThunk(
  "order/updateOrderTracking",
  async (_, { getState }: any) => {
    const state = getState();
    const accountId = state.order.accountId;
    const leadId = state.order.leadId;
    const { trackingDetails } = state.order;
    const { terminationFees } = state.mobile.daisyFreshAmounts;
    const contactId = state.order.orderContactId;
    const ofcomPricingInfo = state.order.ofcomPricingInfo;

    return await OrdersAPI.update(state.order.id, {
      account: accountId,
      lead_id: leadId,
      ...(isValidEmail(trackingDetails) && { tracking_email: trackingDetails }),
      ...(isValidMobileNumber(trackingDetails) && {
        tracking_mobile: trackingDetails,
      }),
      ...(parseFloat(terminationFees) > 0 && {
        additional_contract_notes: `A maximum termination fee of £${terminationFees}.`,
      }),
      ...(contactId && { order_contact_id: contactId }),
      ...(state.order.cc_sales_person && { cc_sales_person: 1 }),
      ...(state.order.suppress_welcome_email && { suppress_welcome_email: 1 }),
      customer_purchase_number: state.order.customer_purchase_number,
      etf_method: state.order.etfMethod,
      ...(ofcomPricingInfo && { ofcom_pricing_info: ofcomPricingInfo }),
    });
  }
);

/**
 * Add all configured products to the order in DC
 * Logic gets complex around order completion. See https://miro.com/app/board/o9J_kqAeNgQ=/
 * @param quoteOnly {Boolean}
 * @returns {Function}
 */
export const addAllProductsToOrder = createAsyncThunk(
  "order/addAllProductsToOrder",
  async (quoteOnly: boolean | undefined, { getState, dispatch }: any) => {
    if (!quoteOnly) quoteOnly = false;
    // Generating a quote requires a similar API conversation to an order,
    // but happens earlier in the process, and tells DC to skip much of the validation.
    if (quoteOnly) {
      await dispatch(setGenerateQuoteFetching());
    }

    // Add any Ethernet products - different process to others.
    if (getState().ethernetProducts.configurations.length > 0) {
      await dispatch(requestAllEthernetPurchases()); // TODO: How does this work for just generating quotes?
    }

    // For TP13926.
    let hasDaisyFresh = false;
    for (const id in getState().hardwareConfigurations) {
      const configuration = getState().hardwareConfigurations[id];
      const daisyFreshQuantity = configuration.daisyFreshQuantity || 0;
      if (daisyFreshQuantity > 0) hasDaisyFresh = true;
    }

    // Add or update Mobile, hardware, WLR+BB and Universal (other) products...
    // Note updates would happen instead of additions if a quote had previously been generated.

    await Promise.all([
      dispatch(orderMobileTariffs(false, quoteOnly)),
      dispatch(orderBoltOns()),
      dispatch(orderShipping()),
      dispatch(orderHardwareCredits()),
      dispatch(orderWlrBroadbandProducts(quoteOnly)),
      dispatch(orderHardware(false)),
      dispatch(orderUniversalProducts()),
      dispatch(orderLogicMonitorProducts()),

      // The order can include Account level bolt-on removals... which are not "part of the order"
      // ....but at the same time..... are.
      !quoteOnly && dispatch(doALBRemovals()),
    ]);

    // For TP13926.
    if (hasDaisyFresh) await dispatch(orderMobileTariffs(false, quoteOnly));

    // Once the order calls have completed, recalculate prices and add tracking details.
    await dispatch(recalculatePrices());

    await dispatch(updateOrderTracking());

    // Orders/View API is called as this API returns information about applied promotions that Orders/Update does not
    await dispatch(getOrderStatus());

    // Only send for approval here if the order requires external approval (LE).
    // Existing user account settings from DC.
    const settings = getAccountSettings(getState());

    // Send for approval if necessary.
    if (
      settings.requires_external_approval_for_external_user_orders === "1" &&
      get(getState().order.orderStatus, "response.data.approval.required") ===
        1 &&
      !quoteOnly
    ) {
      await dispatch(sendForApproval());
    }
  }
);

/**
 * Send Account Level Bol-on removal requests
 *
 * As advised by @ianc, find any ALB product and add it to the order with
 *  - service_cost_id - which is the ID that comes back from `v1/Product/MobileBoltOnSearch`
 *  - is_removal
 *  - fixed_override_name - the name of the bolt-on. (which is just free text for the business.)
 *
 * Note: This used to be a direct request to `v1/Account/RemoveEvoBundle` but following some
 * problems (see FB 132560) this has changed to the above.
 *
 * @returns {Function}
 */
export const doALBRemovals = createAsyncThunk(
  "order/doALBRemovals",
  async (_, { getState, dispatch }: any) => {
    const state = getState();
    if (!state.mobile.boltOnSearch.response?.products) return; // This isn't a mobile order as bolt-ons haven't been fetched.

    const removalData = getALBRemovals(state.mobile);
    const firstBoltOnID =
      state.mobile.boltOnSearch.response.products.length > 0
        ? state.mobile.boltOnSearch.response.products[0].id
        : null;

    const requests = removalData.map(async (removal) => {
      const removalRequest = getState().order.boltOnRemovals[removal.id] || {};
      if (
        removalRequest.fetching ||
        get(removalRequest, "response.status") === "success"
      )
        return;

      await dispatch(removeBoltOn({ firstBoltOnID, removal }));
    });

    return await Promise.all(requests);
  }
);

export const removeBoltOn = createAsyncThunk(
  "order/removeBoltOn",
  async ({ firstBoltOnID, removal }: any, { getState }: any) => {
    const state = getState();
    return await OrderProductAPI.create(firstBoltOnID, {
      account_id: state.order.accountId,
      order_id: state.order.id,
      "mobile_bolt_on-is_removal": 1,
      "mobile_bolt_on-service_cost_id": removal.id,
      skip_price_recalculations: 1,
    });
  }
);

export const recalculatePrices = createAsyncThunk(
  "order/recalculatePrices",
  async (_, { getState }: any) => {
    const order = getState().order;
    return await OrdersAPI.recalculatePrices(
      order.id,
      order.accountId,
      order.leadId
    );
  }
);

/**
 * Send current order for provisioning.
 * @returns {Function}
 */
export const provisionOrder = createAsyncThunk(
  "order/provisionOrder",
  async (_, { getState }: any) => {
    const { id, accountId } = getState().order;
    return await OrdersAPI.provision(id, accountId);
  }
);

/**
 * Set field value for order
 * // TODO: Bin this.
 *
 * @param name {String}
 * @param value {Mixed}
 *
 * @returns {Function}
 */
export const setField = createAction(
  "order/setField",
  (name: string, value: any) => ({
    payload: { name, value },
  })
);

/**
 * Get latest orders placed on the current account
 * @param refresh
 * @returns {Function}
 */
export const getOrdersForAccount = createAsyncThunk(
  "order/getOrdersForAccount",
  async (refresh: boolean | undefined, { getState }: any) => {
    if (!refresh) refresh = false;
    if (
      !refresh &&
      getState().order.ordersForAccount.response.status === "success"
    )
      return;

    return await OrdersAPI.OrdersForAccount(getState().order.accountId);
  }
);

/**
 * Get the next 20 orders on the current account
 * @returns {Function}
 */
export const getMoreOrdersForAccount = createAsyncThunk(
  "order/getMoreOrdersForAccount",
  async (_, { getState }: any) => {
    const nextPage = getState().order.ordersForAccount.response.next_page;
    if (nextPage) {
      return await OrdersAPI.MoreOrdersForAccount(nextPage.split("/v1/")[1]);
    }
  }
);

/**
 * Get the first open pricing request task for the order if it has one.
 * @returns {(function(*, *): Promise<void>)|*}
 */
export const getPricingRequest = createAsyncThunk(
  "order/getPricingRequest",
  async (_, { getState }: any) => {
    const orderId = getOrderId(getState());
    const accountId = getAccountId(getState());
    return await GuidedSalesAPI.AddPricingRequestTaskGet(accountId, orderId);
  }
);

/**
 * Add a pricing request task and return it in the response
 * @param task
 * @returns {(function(*, *): Promise<void>)|*}
 */
export const postPricingRequest = createAsyncThunk(
  "order/postPricingRequest",
  async (task: string, { getState }: any) => {
    const orderId = getOrderId(getState());
    const accountId = getAccountId(getState());
    return await GuidedSalesAPI.AddPricingRequestTaskPost(
      accountId,
      orderId,
      task
    );
  }
);

/**
 * Set the account ID for this session.
 * TODO: Maybe this shouldn't be it's own action? Have a single account ID and update it as part of account create call etc.
 * @param id
 * @returns {{id: *, type: *}}
 */
export const setAccountId = createAction<string>("order/setAccountId");

/**
 * Set the platform customer reference for this session.
 * @param {*} id
 * @returns {{id: *, type: *}}
 */
export const setCustomerReference = createAction<string>(
  "order/setCustomerReference"
);

/**
 * Reset the order state to initial values
 * This is used by the plaform to allow for multiple wizard completions in same session
 * @returns {{ type: string }}
 */
export const resetOrderState = createAction("order/resetOrderState");

/**
 * Sets the total one off price for the order that will be
 * dispalyed on Summary when reopening order
 *
 * @param price
 * @returns {{price: *, type: *}}
 */
export const setTotalOneOffPrice = createAction<any>(
  "order/setTotalOneOffPrice"
);

/**
 * Sets the recurring price for the order that will be
 * dispalyed on Summary when reopening order
 *
 * @param price
 * @returns {{price: *, type: *}}
 */
export const setRecurringPrice = createAction<any>("order/setRecurringPrice");

export const rebookExpiredReservations = createAsyncThunk(
  "order/rebookExpiredReservations",
  async (_, { dispatch }: any) => {
    const actionResp = await dispatch(fetchExpiredReservations());
    const response = actionResp.payload;
    await dispatch(getOrderStatus());
    dispatch(saveDraftState({}));
    return response;
  }
);

export const fetchExpiredReservations = createAsyncThunk(
  "order/fetchExpiredReservations",
  async (_, { getState }: any) => {
    const state = getState();
    const response = await OrdersAPI.rebookExpiredReservations(state.order.id);
    return response;
  }
);

export const uploadDocuments = createAsyncThunk(
  "order/uploadDocuments",
  async (send_with_contract: string = "0", { getState }: any) => {
    const state = getState();
    const response = await OrdersAPI.uploadDocumentsPost(
      state.order.id,
      state.order.documentsUpload.files,
      send_with_contract
    );
    return response;
  }
);

export const resetUploadDocuments = createAction("order/resetUploadDocuments");

export const setDocumentsMeta = createAction(
  "order/setDocumentsMeta",
  (key: any, value: any) => ({
    payload: { key, value },
  })
);

export const listDocuments = createAsyncThunk(
  "order/listDocuments",
  async (_, { getState }: any) => {
    const state = getState();
    return await OrdersAPI.listDocuments(state.order.id);
  }
);
