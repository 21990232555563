import React, { ChangeEvent } from "react";

import { Checkbox, TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { IndexedCardInfo } from "@akj-dev/shared-components";

import { HostEnv } from "../../../Cpq";
import { useAccountSettings } from "../../../shared/hooks/useAccountSettings";

interface OrderContainerProps {
  cardIndex: string;
  order: Record<string, any>;
  hostEnv: HostEnv;
  setReceiveUpdatesField: (
    _: ChangeEvent<HTMLInputElement>,
    isInputChecked: boolean
  ) => void;
  setWelcomeEmailField: (
    _: ChangeEvent<HTMLInputElement>,
    isInputChecked: boolean
  ) => void;
  setCustomerPurchaseNumber: (newCustomerPurchaseNumber: string) => void;
  setOfcomPricingInfo: (ofcomPricingInfo: string) => void;
}

export const Order = ({
  cardIndex,
  order,
  hostEnv,
  setReceiveUpdatesField,
  setWelcomeEmailField,
  setCustomerPurchaseNumber,
  setOfcomPricingInfo,
}: OrderContainerProps) => {
  const accountSettings = useAccountSettings();

  return (
    <IndexedCardInfo index={cardIndex} title="Order">
      <TextField
        variant="standard"
        label="Purchase order number"
        fullWidth
        onChange={(event) => setCustomerPurchaseNumber(event.target.value)}
        value={order.customer_purchase_number || ""}
      />
      {accountSettings.as_can_set_order_updates === "1" && (
        <FormControlLabel
          label="I want to receive updates on this order"
          control={
            <Checkbox
              color="secondary"
              name="cc_sales_person"
              onChange={setReceiveUpdatesField}
              checked={order.cc_sales_person || false}
            />
          }
        />
      )}
      {accountSettings.as_ofcom_text_required === "1" && (
        <TextField
          variant="standard"
          label="Ofcom Pricing Information"
          fullWidth
          multiline
          minRows={4}
          onChange={(event) => setOfcomPricingInfo(event.target.value)}
          value={order.ofcomPricingInfo || ""}
        />
      )}
      {accountSettings.dws_reseller_enabled !== "1" &&
        accountSettings.can_access_vf_direct !== "1" &&
        hostEnv !== "my_account" && (
          <>
            <FormControlLabel
              label="Send welcome email"
              control={
                <Checkbox
                  color="secondary"
                  onChange={setWelcomeEmailField}
                  checked={!order.suppress_welcome_email}
                  data-cy="sendWelcomeEmail"
                />
              }
            />
          </>
        )}
    </IndexedCardInfo>
  );
};
