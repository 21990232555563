import React, { ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setField } from "../../../store/order/actions";
import { getHostEnv } from "../../../store/order/selectors/getHostEnv";
import { Order } from "../components/Order";

interface OrderContainerProps {
  cardIndex: string;
}

export const OrderContainer = ({ cardIndex }: OrderContainerProps) => {
  const dispatch = useDispatch();
  const order = useSelector((state: any) => state.order);
  const hostEnv = useSelector(getHostEnv);

  const setReceiveUpdatesField = (
    _: ChangeEvent<HTMLInputElement>,
    isInputChecked: boolean
  ) => dispatch(setField("cc_sales_person", isInputChecked));

  const setWelcomeEmailField = (
    _: ChangeEvent<HTMLInputElement>,
    isInputChecked: boolean
  ) => dispatch(setField("suppress_welcome_email", !isInputChecked));

  const setCustomerPurchaseNumber = (newCustomerPurchaseNumber: string) =>
    dispatch(setField("customer_purchase_number", newCustomerPurchaseNumber));

  const setOfcomPricingInfo = (ofcomPricingInfo: string) =>
    dispatch(setField("ofcomPricingInfo", ofcomPricingInfo));

  return (
    <Order
      cardIndex={cardIndex}
      hostEnv={hostEnv}
      order={order}
      setCustomerPurchaseNumber={setCustomerPurchaseNumber}
      setReceiveUpdatesField={setReceiveUpdatesField}
      setWelcomeEmailField={setWelcomeEmailField}
      setOfcomPricingInfo={setOfcomPricingInfo}
    />
  );
};
